<template>
    <div>
        <div class="box-body" style="margin-top: -35px;">
    <a-row :gutter="16">
      <a-col :span="24" :md="12">
        <a-descriptions :title="`Código : ${producto.codigo}`" layout="vertical">
          <a-descriptions-item label="Nombre">
            {{ producto.nombre }}
          </a-descriptions-item>
          <a-descriptions-item label="Medida">
            {{ producto.medida }}
          </a-descriptions-item>
          <a-descriptions-item label="Proveedor">
            {{ producto.nombreProveedor }}
          </a-descriptions-item>
          <a-descriptions-item label="Categoria">
            {{ producto.nombreCategoria }}
          </a-descriptions-item>
          <a-descriptions-item label="Fecha Registro">
            {{ producto.fechaIngreso }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="24" :md="12">
        <a-descriptions title="Descripción del Producto" layout="vertical">
          <a-descriptions-item label="Marca">
            {{ producto.nombreMarca }}
          </a-descriptions-item>
          <a-descriptions-item label="Unidad">
            {{ producto.nombreUnidad }}
          </a-descriptions-item>
          <a-descriptions-item label="Procedencia">
            {{ producto.nombreProcedencia }}
          </a-descriptions-item>
          <a-descriptions-item label="Fecha Elaboración">
            {{ producto.elaboracion }}
          </a-descriptions-item>
          <a-descriptions-item label="Fecha Vencimiento">
            {{ producto.vencimiento }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-row :gutter="16" style="margin-top: 20px;">
      <a-col :span="24" :md="10">
        <label><strong>Paginacion:</strong></label><br>
        <a-button @click="fetchPaginateList(pagination.prev_page_url)" :disabled="!pagination.prev_page_url">
          Pagina Anterior
        </a-button>
        <span>Pagina : {{ pagination.current_page }} de {{ pagination.last_page }}</span>
        <a-button @click="fetchPaginateList(pagination.next_page_url)" :disabled="!pagination.next_page_url">
          Pagina Siguiente
        </a-button>
      </a-col>
      <a-col :span="24" :md="14">
        <a-row :gutter="16">
          <a-col :span="24" :md="8">
            <a-form-item label="Fecha Inicio">
              <a-date-picker size="large" :value="getDateFormat(consulta.fecha_inicio)" @change="onChangeFechaInicio" :locale="locale" style="width: 100%"/>
            </a-form-item>
          </a-col>
          <a-col :span="24" :md="8">
            <a-form-item label="Fecha Final">
              <a-date-picker size="large" :value="getDateFormat(consulta.fecha_final)" @change="onChangeFechaFinal" :locale="locale" style="width: 100%"/>
            </a-form-item>
          </a-col>
          <a-col :span="24" :md="8">
            <a-form-item label="Acción">
              <a-button size="large" type="primary" @click="BuscarSalidaPorFechas()">
                <a-icon type="schedule" /> Buscar Ahora
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
  
  <div class="box-body table-responsive no-padding">
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th><center>COD</center></th>
          <th><center>FECHA</center></th>
          <th><center>DETALLE</center></th>
          <th><center>P.COMPRA <br> NORMAL</center></th>
          <th><center>P.COMPRA <br> PROMEDIO</center></th>
          <th><center>P.VENTA</center></th>
          <th><center>ENTRADA</center></th>
          <th><center>SALIDA</center></th>
          <th><center>SALDO <br> FISICO</center></th>
          <th><center>INGRESO</center></th>
          <th><center>EGRESO</center></th>
          <th><center>SALDO <br> VALORADO</center></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="index">
          <td style="font-weight:700; font-size:11px;">{{ item.codigo }}</td>
          <td>
            <span style="font-size:10px; font-weight:700;">Reg:</span> <span style="font-size:11px;">{{ formatDate(item.created_at) }}</span><br>
            <span style="font-size:10px; font-weight:700;">Trans:</span> <span style="font-size:11px;">{{ item.fecha }} {{ item.horaregistro }}</span><br>
          </td>
          <td>
            
<!-- Inventario Inicial -->
<a-tag v-if="item.detalle === 1" :color="'#0033A0'" style="background: transparent; border-color: #0033A0; color: #0033A0;">Inventario Inicial</a-tag>

<!-- Compras -->
<a-tag v-else-if="item.detalle === 2" :color="'#009688'" style="background: transparent; border-color: #009688; color: #009688;">Compra sin Factura</a-tag>
<a-tag v-else-if="item.detalle === 3" :color="'#4CAF50'" style="background: transparent; border-color: #4CAF50; color: #4CAF50;">Compra con Factura</a-tag>
<a-tag v-else-if="item.detalle === 8" :color="'#FF9800'" style="background: transparent; border-color: #FF9800; color: #FF9800;">Compra a Crédito</a-tag>
<a-tag v-else-if="item.detalle === 13" :color="'#FF5722'" style="background: transparent; border-color: #FF5722; color: #FF5722;">Compra Anulada sin Factura</a-tag>
<a-tag v-else-if="item.detalle === 14" :color="'#FFC107'" style="background: transparent; border-color: #FFC107; color: #FFC107;">Compra Anulada con Factura</a-tag>
<a-tag v-else-if="item.detalle === 15" :color="'#795548'" style="background: transparent; border-color: #795548; color: #795548;">Ingreso por Importación</a-tag>

<!-- Ventas -->
<a-tag v-else-if="item.detalle === 4" :color="'#213ef3'" style="background: transparent; border-color: #213ef3; color: #213ef3;">Venta sin Factura</a-tag>
<a-tag v-else-if="item.detalle === 5" :color="'#FF5722'" style="background: transparent; border-color: #FF5722; color: #FF5722;">Venta a Crédito</a-tag>
<a-tag v-else-if="item.detalle === 10" :color="'#9E9E9E'" style="background: transparent; border-color: #9E9E9E; color: #9E9E9E;">Venta Anulada sin Factura</a-tag>
<a-tag v-else-if="item.detalle === 12" :color="'#1976D2'" style="background: transparent; border-color: #1976D2; color: #1976D2;">Venta con Factura</a-tag>

<!-- Ajustes -->
<a-tag v-else-if="item.detalle === 7" :color="'#D32F2F'" style="background: transparent; border-color: #D32F2F; color: #D32F2F;">Ajuste de Inventario Salida</a-tag>
<a-tag v-else-if="item.detalle === 9" :color="'#388E3C'" style="background: transparent; border-color: #388E3C; color: #388E3C;">Ajuste de Inventario Entrada</a-tag>

<!-- Transferencias -->
<a-tag v-else-if="item.detalle === 16" :color="'#F57C00'" style="background: transparent; border-color: #F57C00; color: #F57C00;">Traspaso de Salida</a-tag>
<a-tag v-else-if="item.detalle === 17" :color="'#388E3C'" style="background: transparent; border-color: #388E3C; color: #388E3C;">Traspaso de Entrada</a-tag>

<!-- Actualización Costo Compra -->
<a-tag v-else-if="item.detalle === 11" :color="'#FBC02D'" style="background: transparent; border-color: #FBC02D; color: #FBC02D;">Actualización Costo de Compra</a-tag>

<!-- Actualización de Venta -->
<a-tag v-else-if="item.detalle === 18" :color="'#D32F2F'" style="background: transparent; border-color: #D32F2F; color: #D32F2F;">Salida cantidad Venta Actualizado</a-tag>
<a-tag v-else-if="item.detalle === 19" :color="'#1976D2'" style="background: transparent; border-color: #1976D2; color: #1976D2;">Entrada cantidad Venta Actualizado</a-tag>

<!-- Actualización Costo Compra -->
<a-tag v-else-if="item.detalle === 20" :color="'#1976D2'" style="background: transparent; border-color: #1976D2; color: #1976D2;">Salida de Venta Actualizado</a-tag>

			    <br>		
			
			<span style="font-size:10px; font-weight:700;">Documento:</span> <span style="font-size:12px; font-weight:700; color:black;">{{ item.codigoDocumento }}</span><br>
            <span style="font-size:10px; font-weight:700;">Usuario:</span> <span style="font-weight:700; font-size:12px;">{{ item.usuario }}</span>
          </td>
          <td style="font-size:15px;"><center>{{ item.costo }}</center></td>
          <td style="font-size:15px;"><center>{{ item.costo_real }}</center></td>
          <td bgcolor="#eefaff" style="color:#297b77; font-weight:700; font-size:15px;"><center>{{ item.precioventa }}</center></td>
          <td bgcolor="#ffffea" style="color:green; font-weight:700; font-size:17px;"><center>{{ item.entrada }}</center></td>
          <td style="background-color:#fff8d3; color:#FF451C; font-weight:700; font-size:17px;"><center>{{ item.salida }}</center></td>
          <td style="background-color:#f1ffde; color:green; font-weight:700; font-size:18px;"><center>{{ item.saldoFisico }}</center></td>
          <td bgcolor="#ffffea" style="color:green; font-weight:700; font-size:15px;"><center>{{ item.ingreso }}</center></td>
          <td style="background-color:#fff8d3; color:#FF451C; font-weight:700; font-size:15px;"><center>{{ item.egreso }}</center></td>
          <td style="background-color:#f1ffde; color:green; font-weight:700; font-size:16px;"><center>{{ item.saldoValorado }}</center></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th style="background-color:#ffffdd; color:#08540d;"><center><font size="2">Saldo Fisico</font></center></th>
          <th style="background-color:#ffffdd; color:#08540d;"><center><strong><font size="5">{{ producto.saldoFisico }}</font></strong></center></th>
          <th></th>
          <th style="background-color:#ffffdd; color:#08540d;"><center><font size="2">Saldo Valorado</font></center></th>
          <th style="background-color:#ffffdd; color:#08540d;"><center><strong><font size="5">{{ utils.formatMoney(producto.saldoValorado, this.config.compradecimal, '.', '') }}</font></strong></center></th>
        </tr>
      </tfoot>
    </table>
  </div>
  
  <div class="pagination">
    <a-button @click="fetchPaginateList(pagination.prev_page_url)" :disabled="!pagination.prev_page_url">
      Pagina Anterior
    </a-button>
    <span>Pagina : {{ pagination.current_page }} de {{ pagination.last_page }}</span>
    <a-button @click="fetchPaginateList(pagination.next_page_url)" :disabled="!pagination.next_page_url">
      Pagina Siguiente
    </a-button>
  </div>
        
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 99999;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    </div>
</template>

<script>
    import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import { EuropioCode } from '@/utils/europiocode'
	import axios from '@/config/axios/axios'
	import { mapGetters, mapActions } from 'vuex'

    import { 
		URI_KARDEX_LISTAR,
        URI_KARDEX_BUSCAR
	} from '@/utils/constants'

    export default {

        name: 'kardex',

        props: [
            'almacenid',
            'productoid'
		],

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
				utils,
                list: [],
                pagination: [],
                spinnerloading: false,
                producto: {
                    producto_id: '',
                    codigo: '',
                    nombre: '',
                    descripcion: '',
                    medida: '',
                    fechaIngreso: '',
                    elaboracion: '',
                    vencimiento: '',
                    nombreMarca: '',
                    nombreUnidad: '',
                    nombreCategoria: '',
                    nombreProcedencia: '',
                    nombreProveedor: '',
                    saldoFisico: '',
                    saldoValorado: '',
                    almacenID: ''
                },
                last_page_list: '',
                consulta: {
					fecha_inicio: '',
                    fecha_final: ''
				}
            }
        }, 

        mounted () {
           this.getLastKardex()
        },

		computed: {
			...mapGetters('configuracion', ['config'])
        },

        methods: {

            truncarCadenaConPalabraCompleta (cadena, tamaño, elipses) {
                if (cadena.length <= tamaño) {
                    return cadena
                }
                const elipse = elipses || '...'
                let caracteres = 0
                const arreglo = cadena.split(' ')
                for (var i in arreglo) {
                    caracteres += arreglo[i].length
                    if (caracteres > tamaño) {
                    arreglo.splice(i, arreglo.length)
                    }
                    caracteres++
                }
                return arreglo.join(' ') + elipse
            },

            removeHtmlTagFromString (strHtmlCodes) {
                const strHtmlCode = strHtmlCodes.replace(/&(lt|gt);/g,
                function (strMatch, p1) {
                    return (p1 === 'lt') ? '<' : '>'
                })
                return strHtmlCode.replace(/<\/?[^>]+(>|$)/g, '')
            },

            getDateFormat (date) {
				var d = moment(date)
				return date && d.isValid() ? d : ''
			},

            decodeHTMLEntities (str) {
                return str.replace(/&#(\d+);/g, function (match, dec) {
                    return String.fromCharCode(dec)
                })
            },

            sortArrays (arrays) {
                const _ = ''
                return _.orderBy(arrays, 'fullname', 'asc')
            },

            formatBytes (bytes, decimals = 2) {
                if (bytes === 0) return '0 Bytes'

                const k = 1024
                const dm = decimals < 0 ? 0 : decimals
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

                const i = Math.floor(Math.log(bytes) / Math.log(k))

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
            },

            formatDate (value) {
                return moment(value).format('DD/MM/YYYY h:mm:ss A')
            },

			getCleanNumbersInString (string) {
				var tmp = string.split('')
				var map = tmp.map(function (current) {
					if (!isNaN(parseInt(current))) {
					return current
					}
				})
				var numbers = map.filter(function (value) {
					return value !== undefined
				})
				return numbers.join('')
			},

            makePagination (data) {
				const pagination = {
					current_page: data.current_page,
					last_page: data.last_page,
					next_page_url: (data.next_page_url) ? this.getCleanNumbersInString(data.next_page_url) : data.next_page_url,
					prev_page_url: (data.prev_page_url) ? this.getCleanNumbersInString(data.prev_page_url) : data.prev_page_url
				}
				this.pagination = pagination
            },

            fetchPaginateList (url) {
				const page = url
                this.getKardex(page)
            },

            getLastKardex (page = 1) {
                this.spinnerloading = true
                const $this = this
                const formData = new FormData()
                formData.append('almacenID', this.almacenid)
                formData.append('productoID', this.productoid)
                formData.append('page', page)

                axios.post(URI_KARDEX_LISTAR, formData)
                .then(response => {
                    this.last_page_list = response.data.kardex.last_page
                    this.getKardex(this.last_page_list)
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            getKardex (page = 1) {
                const $this = this
                const formData = new FormData()
                formData.append('almacenID', this.almacenid)
                formData.append('productoID', this.productoid)
                formData.append('page', page)

                axios.post(URI_KARDEX_LISTAR, formData)
                .then(response => {
                    this.producto.producto_id = response.data.producto.producto_id
                    this.producto.codigo = response.data.producto.codigo
                    this.producto.nombre = response.data.producto.nombre
                    this.producto.descripcion = response.data.producto.descripcion
                    this.producto.medida = response.data.producto.medida
                    this.producto.fechaIngreso = response.data.producto.fechaIngreso
                    this.producto.elaboracion = response.data.producto.elaboracion
                    this.producto.vencimiento = response.data.producto.vencimiento
                    this.producto.nombreMarca = (response.data.producto.marca) ? response.data.producto.marca.nombre : ''
                    this.producto.nombreUnidad = (response.data.producto.unidad) ? response.data.producto.unidad.nombre : ''
                    this.producto.nombreCategoria = (response.data.producto.categoria) ? response.data.producto.categoria.nombre : ''
                    this.producto.nombreProcedencia = response.data.producto.procedencia.nombre
                    this.producto.nombreProveedor = response.data.producto.proveedor.empresa
                    this.producto.saldoFisico = response.data.producto.stock.saldoFisico
                    this.producto.saldoValorado = response.data.producto.stock.saldoValorado
                    this.producto.almacenID = response.data.producto.stock.almacenId

                    this.list = response.data.kardex.data
                    $this.makePagination(response.data.kardex)
                    this.spinnerloading = false
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            onChangeFechaInicio (date, dateString) {
				this.consulta.fecha_inicio = dateString
			},

            onChangeFechaFinal (date, dateString) {
				this.consulta.fecha_final = dateString
			},

            BuscarSalidaPorFechas (page = 1) {
                this.spinnerloading = true
                const $this = this
                const ecode = new EuropioCode()
                const formData = new FormData()
                formData.append('almacenID', this.almacenid)
                formData.append('productoID', this.productoid)
                formData.append('page', page)
                formData.append('fecha_inicio', (this.consulta.fecha_inicio === null) ? null : ecode.encode(this.consulta.fecha_inicio))
                formData.append('fecha_final', (this.consulta.fecha_final === null) ? null : ecode.encode(this.consulta.fecha_final))

                axios.post(URI_KARDEX_BUSCAR, formData)
                .then(response => {
                    this.producto.producto_id = response.data.producto.producto_id
                    this.producto.codigo = response.data.producto.codigo
                    this.producto.nombre = response.data.producto.nombre
                    this.producto.descripcion = response.data.producto.descripcion
                    this.producto.medida = response.data.producto.medida
                    this.producto.fechaIngreso = response.data.producto.fechaIngreso
                    this.producto.elaboracion = response.data.producto.elaboracion
                    this.producto.vencimiento = response.data.producto.vencimiento
                    this.producto.nombreMarca = (response.data.producto.marca) ? response.data.producto.marca.nombre : ''
                    this.producto.nombreUnidad = (response.data.producto.unidad) ? response.data.producto.unidad.nombre : ''
                    this.producto.nombreCategoria = (response.data.producto.categoria) ? response.data.producto.categoria.nombre : ''
                    this.producto.nombreProcedencia = response.data.producto.procedencia.nombre
                    this.producto.nombreProveedor = response.data.producto.proveedor.empresa
                    this.producto.saldoFisico = response.data.producto.stock.saldoFisico
                    this.producto.saldoValorado = response.data.producto.stock.saldoValorado
                    this.producto.almacenID = response.data.producto.stock.almacenId

                    this.list = response.data.kardex.data
                    $this.makePagination(response.data.kardex)
                    this.spinnerloading = false
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            }

        }
    }
</script>

<style>

    .modal-backdrop.show {
        opacity: 0.92 !important;
    }
    
    .modal-backdrop, .side-modal , .mostrar {
        display: block !important; 
    }

    .modal-dialog {
        position: relative;
        width: auto;
        max-width: 850px;
        margin: 10px;
    }

    .modal-dialog-x {
        position: relative;
        width: auto;
        max-width: 1500px;
        margin: 10px;
    }

    .modal-sm {
        max-width: 300px;
    }
    
    .modal-lg {
        max-width: 850px;
    }

    .modal-lg-x {
        max-width: 1500px;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    @media (min-width: 768px) {
        .modal-dialog {
            margin: 30px auto;
        }
    }
    @media (min-width: 320px) {
        .modal-sm {
            margin-right: auto;
            margin-left: auto;
        }
    }
    @media (min-width: 620px) {
        .modal-dialog {
            margin-right: auto;
            margin-left: auto;
        }
        .modal-lg {
            margin-right: 10px;
            margin-left: 10px;
        }
    }
    @media (min-width: 920px) {
        .modal-lg {
            margin-right: auto;
            margin-left: auto;
        }
    }

    .btn-opciones-green {
        background-color:#54d406;
        color:#fff;
    }

    .btn-opciones-blue {
        background-color:#024bac;
        color:#fff;
    }

    .tabla-default-primary{
        background-color:#e1e1e1;
        border-top: 3px solid rgb(32, 80, 129);
    }

    /*== BOTON DE PRECIO DE VENTA ===*/
    .boton-asignar{ top: 24px; }

    @media (max-width: 414px) {
        .boton-asignar{ top: 0px; }
    }

    @media (max-width: 375px) {
        .boton-asignar{ top: 0px; }
    }

    @media (max-width: 360px) {
        .boton-asignar{ top: 0px; }
    }

    @media (max-width: 320px) {
        .boton-asignar{ top: 0px; }
    }

    .example {
    margin: 20px;
    }
    .example input {
    display: none;
    }
    .example label {
    margin-right: 20px;
    display: inline-block;
    cursor: pointer;
    }

    .ex1 span {
    display: block;
    padding: 5px 10px 5px 25px;
    border: 2px solid #ddd;
    border-radius: 5px;
    position: relative;
    transition: all 0.25s linear;
    }
    .ex1 span:before {
    content: '';
    position: absolute;
    left: 5px;
    top: 50%;
    -webkit-transform: translatey(-50%);
            transform: translatey(-50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ddd;
    transition: all 0.25s linear;
    }
    .ex1 input:checked + span {
    background-color: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    }
    .ex1 .red input:checked + span {
    color: red;
    border-color: red;
    }
    .ex1 .red input:checked + span:before {
    background-color: red;
    }
    .ex1 .green input:checked + span {
    color: rgb(31, 211, 14);
    border-color: rgb(31, 211, 14);
    }
    .ex1 .green input:checked + span:before {
    background-color: rgb(31, 211, 14);
    }
    .ex1 .blue input:checked + span {
    color: rgb(45, 186, 204);
    border-color: rgb(45, 186, 204);
    }
    .ex1 .blue input:checked + span:before {
    background-color: rgb(45, 186, 204);
    }
    .ex1 .orange input:checked + span {
    color: orange;
    border-color: orange;
    }
    .ex1 .orange input:checked + span:before {
    background-color: orange;
    }
    .ex1 .lila input:checked + span {
    color: rgb(172, 45, 204);
    border-color: rgb(172, 45, 204);
    }
    .ex1 .lila input:checked + span:before {
    background-color: rgb(172, 45, 204);
    }

</style>