<template>
	<div>
        <div class="col-md-12">
            <div class="form-group">
                <label>Agregar {{ titulo }}:</label>
                <br>
                <a-upload-dragger
                    :multiple="false"
                    :customRequest="agregarFile"
                    :remove="handleRemovePortada"
                    :before-upload="beforeUploadPortada"
                    :file-list="form.fileList"
                    name="file"
                    list-type="picture"
                    :accept="accept"
                    @change="handleChangePortada"
                    @preview="handlePreviewPortada"
                >
                    <p class="ant-upload-drag-icon"><a-icon type="file-pdf" /></p>
                    <p class="ant-upload-text">{{ titulo }}</p>
                    <p class="ant-upload-hint">
                        Haga clic o arrastre el archivo
                    </p>
                </a-upload-dragger>
            </div>
        </div>
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
	</div>
</template>

<script>
	import * as utils from '@/utils/utils'

	export default {

        props: {
			titulo: {
				type: String,
				required: true
			},

            accept: {
				type: String,
				required: true
			}
		},

		data () {
			return {
                utils,
                form: {
                    file: [],
                    fileList: [],
                    estado: ''
                },
                previewVisibleImagen: false,
                spinnerloading: false,
                loading: false
			}
		},

		methods: {

            agregarFile (info) {
                console.log('Agregando File')
                if (this.form.estado === 'DESHABILITADO') {
                    this.form.file = []
                    this.form.fileList = []
                } else if (this.form.estado === 'HABILITADO') {
                    this.form.file.push(info.file)
                }
			},

            handleRemovePortada (info) {
                console.log('Remove File')
                utils.openNotificationWithIcon('warning', 'Atencion:', 'Ha eliminado la Imagen...', 'topRight')
			},

            beforeUploadPortada (file) {
                console.log('Antes de cargar file')
				const isFile = file.type === this.accept // 'application/pdf'
				if (!isFile) {
                    this.form.estado = 'DESHABILITADO'
                    this.form.file = []
                    this.form.fileList = []
                    utils.openNotificationWithIcon('warning', 'Atencion:', 'Debe ser un archivo valido...', 'topRight')
				} else {
                    this.form.estado = 'HABILITADO'
                }
			},

            handleChangePortada ({ fileList }) {
                console.log('Cambiando file')
                if (this.form.estado === 'DESHABILITADO') {
                    this.form.file = []
                    this.form.fileList = []
                } else if (this.form.estado === 'HABILITADO') {
                    this.form.fileList = fileList
                    setTimeout(function () {
                        const file = this.form.file
                        this.$emit('uploadFile', file)
                    }.bind(this), 100)
                }
			},

            async handlePreviewPortada (file) {
                console.log('Preview de Imagen')
			},

            handleCancelModalImagen () {
                console.log('Cancel Modal')
			}
		}

	}
</script>