<template>
    <div>
        <a-row type="flex" :gutter="[2, 5]">
			<a-divider orientation="left">
				<span style="color:black; font-size:18px;">Inventarios</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza tus stocks de inventarios</span>
			</a-divider>

            <a-col flex="1 1 200px" v-if="$can('existencia.consultar.inventario.por.sucursal')">
                <a-select label-in-value :value="{ key: consulta.almacen_id }" @change="onSearchSucursal"  style="width: 100%">
                    <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                </a-select>
            </a-col>

            <a-col flex="1 10 150px" v-if="$can('existencia.consultar.inventario.por.usuario')">
                <a-select  :value="consulta.proveedor_id" @change="onSearchProveedor" placeholder="Seleccionar" show-search option-filter-prop="children" :filter-option="filterOptionProveedor" style="width: 100%">
                    <a-select-option v-for="(value, index) in proveedores" :key="index" :value="value.proveedor_id"> {{ value.empresa }}</a-select-option>
                </a-select>
			</a-col>

            <a-col flex="1 1 150px">
				<a-dropdown>
                    <a-menu slot="overlay">
                        <a-menu-item @click="AbrirActualizarCostos()" :key="buttonKeyActualizarCostos" v-if="$can('existencia.administrar.generar.precios.de.compra')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">Generar precios de compra</span>
                                </template>
                                <a-icon type="dollar" :style="{ fontSize: '18px', color: '#006ccc' }"/> <strong>Generar Precios de Compra</strong>
                            </a-tooltip>
                        </a-menu-item>
						<a-menu-item @click="ActualizarSaldos()" :key="buttonKeyActualizarSaldos" v-if="$can('existencia.administrar.actualizar.saldos.inventario')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">Actualizar Saldos Inventario</span>
                                </template>
                                <a-icon type="cloud-sync" :style="{ fontSize: '18px', color: '#009011' }"/> <strong>Actualizar los Saldos Inventario</strong>
                            </a-tooltip>
                        </a-menu-item>
                        <a-menu-item @click="AbrirActualizarFechas()" :key="buttonKeyActualizarFechas" v-if="$can('existencia.administrar.corregir.verificar.fechas.inventario')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">Corregir y Verificar Fechas</span>
                                </template>
                                <a-icon type="calendar" :style="{ fontSize: '18px', color: '#008B9E' }"/> <strong>Corregir y Verificar las Fechas de Inventario</strong>
                            </a-tooltip>
                        </a-menu-item>
                        <a-menu-item @click="abrirAjustePrecio()" :key="buttonKeyAjustePrecio" v-if="$can('existencia.administrar.ajustar.precios.de.venta')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">Ajustar Precios de Venta</span>
                                </template>
                                <a-icon type="dollar" :style="{ fontSize: '18px', color: '#009E2E' }"/> <strong>Ajustar Precios de Venta</strong>
                            </a-tooltip>
                        </a-menu-item>
                        <a-menu-item @click="abrirPrecioVenta()" :key="buttonKeyPrecioVenta" v-if="$can('existencia.administrar.generar.precios.de.venta')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">Generar Precios de Venta Global</span>
                                </template>
                                <a-icon type="dollar" :style="{ fontSize: '18px', color: '#009E2E' }"/> <strong>Generar Precios de Venta</strong>
                            </a-tooltip>
                        </a-menu-item>
                        <a-menu-item @click="descargaPlantillaExcel()" :key="buttonKeyPlantillaImportacionExcel" v-if="$can('existencia.administrar.descargar.plantilla.importacion')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">Plantilla de Importacion de Inventario</span>
                                </template>
                                <a-icon type="download" :style="{ fontSize: '18px', color: '#047AAD' }"/> <strong>Descargar Plantilla Importacion</strong>
                            </a-tooltip>
                        </a-menu-item>
                        <a-menu-item @click="abrirImportarExcel()" :key="buttonKeyImportacionExcel" v-if="$can('existencia.administrar.importar.inventario.excel')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">Importar Inventario</span>
                                </template>
                                <a-icon type="file-excel" :style="{ fontSize: '18px', color: '#04AF1E' }"/> <strong>Importar Inventario Excel</strong>
                            </a-tooltip>
                        </a-menu-item>
                        <a-menu-item @click="abrirEliminarPrecioVenta()" :key="buttonKeyEliminarPrecioVenta" v-if="$can('existencia.administrar.eliminar.precios.de.venta')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">Eliminar Precios de Venta</span>
                                </template>
                                <a-icon type="delete" :style="{ fontSize: '18px', color: '#E10000' }"/> <strong>Eliminar Precios de Venta</strong>
                            </a-tooltip>
                        </a-menu-item>
                        <a-menu-item @click="abrirModalEliminarInventario()" :key="buttonKeyEliminarInventario" v-if="$can('existencia.administrar.formatear.inventario')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">Formatear Inventario</span>
                                </template>
                                <a-icon type="delete" :style="{ fontSize: '18px', color: '#E10000' }"/> <strong>Formatear Inventario</strong>
                            </a-tooltip>
                        </a-menu-item>
					</a-menu>
					<a-button block> HERRAMIENTAS DE GESTION <a-icon type="down" /> </a-button>
				</a-dropdown>
			</a-col>

			<a-col flex="1 1 370px" :span="24">
                <a-input-search placeholder="Buscar por Codigo / Nombre del Producto" @search="onSearchProducto">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
            </a-col>

            <a-col :span="1">
                <a-button @click="obtenerListadoActual">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>

        <br>

        <a-row type="flex">
            <a-col flex="1 1 200px" :span="8">
                <a-statistic title="Sucursal" :value="informacionSaldo.almacen" />
            </a-col>

            <a-col flex="1 1 200px" :span="8">
                <a-statistic title="Saldo Total" :value="informacionSaldo.stockTotal" />
            </a-col>

            <a-col flex="1 1 200px" :span="8">
                <a-statistic title="Saldo Valorado Total" :value="informacionSaldo.valoradoTotal" />
            </a-col>
        </a-row>

        <br>

        <a-table 
            :columns="columns" 
            :data-source="inventarios" 
            :pagination="paginate"
            :loading="loading"
            :ellipsis="true"
            size="small"
            :rowKey="record => record.stock_id"
            :scroll="{ x: 980 }"
            @change="handlePagination"
            >

            <template slot="codigo" slot-scope="item">
                {{ item.producto.codigo }}
            </template>

            <template slot="proveedor" slot-scope="item">
                {{ item.producto.proveedor.empresa }}
            </template>

            <template slot="nombre" slot-scope="item">
                {{ item.producto.nombre }}
            </template>

            <template slot="medida" slot-scope="item">
                {{ item.producto.medida }}
            </template>
            
            <template slot="saldoFisico" slot-scope="item">
                <span v-if="item.saldoFisico > 0" style="color:#0d8b00; font-size:17px; font-weight:700;">{{ item.saldoFisico }}</span>
                <span v-if="item.saldoFisico === 0" style="color:#8b0000; font-size:17px; font-weight:700;">{{ item.saldoFisico }}</span>
            </template>

            <template slot="costo" slot-scope="item">
                <span v-if="item.costo > 0">{{ item.costo }}</span>
                <span v-else style="color:#8b0000;">{{ item.costo }}</span>
            </template>

            <template slot="saldoValorado" slot-scope="item">
                <span v-if="item.saldoValorado > 0">{{ utils.formatMoney(item.saldoValorado, config.compradecimales, '.', '') }}</span>
                <span v-else style="color:#8b0000;">{{ utils.formatMoney(item.saldoValorado, config.compradecimales, '.', '') }}</span>
            </template>

            <template slot="precioventa" slot-scope="item">
                <div style="margin-top: -8px;" class="color-palette-set" v-for="(option, i) in item.producto.precioventas" v-bind:value="option.tipoprecioventaID" :key="i">
                    <div style="background-color:#d8ffd4; padding: 5px;">
                        <a-tooltip placement="topLeft">
                            <template slot="title">
                                <span style="font-size:11px;">{{ option.proveedor.empresa }}</span>
                            </template>
                                <a href="#"><span style="color:#0d8b00; font-size:17px; font-weight:700;">{{ option.precioValor }}</span>&nbsp;&nbsp;
                                <span style="font-size:9px; font-weight:700; color:#252525 !important;">{{ option.tipoprecioventa.nombre }}</span></a>
                        </a-tooltip>
                    </div>
                </div>
            </template>

            <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
                <a-row>
                    <a-col :span="3">
                        <a-list size="small" bordered><a-list-item>ID:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>CODIGO:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>NOMBRE:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>CATEGORIA:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>UNIDAD:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>MEDIDA:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>MARCA:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>SUCURSAL:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>PROVEEDOR:</a-list-item></a-list>
                    </a-col>
                    <a-col :span="21">
                        <a-list size="small" bordered><a-list-item>{{ item.stock_id }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.producto.codigo }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.producto.nombre }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.producto.categoria.nombre }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.producto.unidad.nombre }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.producto.medida }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.producto.marca.nombre }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>
                            <a-tag color="green">
                                <span style="font-weight:700; font-size:11px;">{{ item.almacen.nombre }}</span>
                            </a-tag>
                        </a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>
                            <a-tag color="blue">
                                <strong>{{ item.producto.proveedor.empresa }}</strong>
                            </a-tag>
                        </a-list-item></a-list>
                    </a-col>
                </a-row>
            </span>

            <template slot="action" slot-scope="item">
                <a-dropdown>
                    <a-menu slot="overlay">
                        
                        <a-menu-item @click.once="openModalKardex(item)" :key="buttonKeyVisualizarKardex" v-if="$can('existencia.visualizar.kardex.por.producto')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">{{ item.producto.codigo }}</span>
                                </template>
                                <a-icon type="form" :style="{ fontSize: '18px', color: '#006ccc' }"/> <strong>Visualizar Kardex</strong>
                            </a-tooltip>
                        </a-menu-item>

                        <a-menu-item @click.once="abrirModalActualizarCosto(item)" :key="buttonKeyEditarCosto" v-if="$can('existencia.editar.precio.costo.unitario.en.inventario')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">{{ item.producto.codigo }}</span>
                                </template>
                                <a-icon type="eye" :style="{ fontSize: '18px', color: '#0c77ff' }"/> <strong>Editar Precio Costo Unitario</strong>
                            </a-tooltip>
                        </a-menu-item>

                        <a-menu-item @click.once="abrirModalAsignarPrecios(item)" :key="buttonKeyNuevaVenta" v-if="$can('existencia.administrar.precio.de.venta.en.inventario')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:12px;">{{ item.producto.codigo }}</span>
                                </template>
                                <a-icon type="dollar" :style="{ fontSize: '18px', color: '#0bb006' }"/> <strong>Precios de Venta</strong>
                            </a-tooltip>
                        </a-menu-item>

                        <a-menu-item @click.once="abrirModalInventarioProducto(item)" :key="buttonKeyCompra" v-if="$can('existencia.administrar.nueva.compra.en.inventario')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:12px;">{{ item.producto.codigo }}</span>
                                </template>
                                <a-icon type="shopping" :style="{ fontSize: '18px', color: '#07b801' }"/> <strong>Nueva Compra</strong>
                            </a-tooltip>
                        </a-menu-item>

                    </a-menu>
                    <a-button> Acciones <a-icon type="down" /> </a-button>
                </a-dropdown>
            </template>
        </a-table>

        <!-- IMPORTAR INVENTARIO EXCEL -->
        <a-modal
            v-model="showModalImportarExcel"
            title="Importar Inventario Excel"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="450"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="almacen_2" class="control-label">SUCURSALES</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.almacen_id }" @change="onSearchSucursal"  style="width: 100%">
                            <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <div class="alert alert-warning" role="alert">
                            <strong>Atención!</strong> Los formatos que se aceptan son : .xlsx
                        </div>
                        <File
                            titulo="Agregar Archivo de Importacion Excel"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @uploadFile="successFileImportacion"
                        />
                    </div>						
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalImportarExcel = false">
                    Cancelar
                </a-button>
                <a-button @click.once="guardarImportacionExcel()" :key="buttonKeyImportacionExcel" type="primary" :loading="loading">
                    Proceder Ahora
                </a-button>
            </template>
        </a-modal>

        <!-- CORRECCION Y ACTUALIZACION FECHAS -->
        <a-modal
            v-model="showModalActualizarFecha"
            title="Correccion y Actualizacion de Fechas Registradas"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="420"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <div class="col-md-12">
                    <center><h2 style="color:#139e6a; font-weight:700;">{{ nombreAlmacenActual }}</h2></center>
                    <center><p>Esta opción actualizará todas las fechas en que se registrarón las transacciones.</p></center>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label for="almacen_2" class="control-label">Seleccionar una Opcion:</label>
                            <a-select size="large" name="ElegirEstadoFecha" :value="fechaestadoelegido" @change="onSearchFechaElegido"  style="width: 100%">
                                <a-select-option v-for="(value, index) in lista_actualizar_fechas" :key="index" :value="value.detalle"> {{ value.nombre }}</a-select-option>
                            </a-select>
                        </div>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalActualizarFecha = false">
                    Cancelar
                </a-button>
                <a-button @click.once="ActualizarFechas()" :key="buttonKeyGenerarCompra" type="primary" :loading="loading">
                    Corregir y Actualizar Fechas Registradas
                </a-button>
            </template>
        </a-modal>

        <!-- NUEVA GESTION INVENTARIO -->
        <a-modal
            v-model="openModalNuevaGestionModal"
            title="Nueva Gestion de Inventario"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="600"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <div class="col-md-12">
                    <div class="form-group">
                        <p style="color:black; font-size:18px;">Este proceso hara la siguiente función: " El kardex actual se guardará en una bitacora del kardex para el historial y procederá a formatear el registro de kardex actual para una nueva gestión de inventario en el sistema. "</p>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="openModalNuevaGestionModal = false">
                    Cancelar
                </a-button>
                <a-button @click.once="RegistrarGestionInventario()" :key="buttonKey" type="inverse" :loading="loading">
                    Proceder Ahora
                </a-button>
            </template>
        </a-modal>

        <!-- ACTUALIZAR PRECIO COSTO -->
        <a-modal
            v-model="modalPrecioCostoUnitario"
            title="ACTUALIZAR PRECIO COSTO UNITARIO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="450"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <table width="100%" border="1">
                    <tr>
                        <td style="font-size:13px; font-weight:700;">CODIGO: </td>
                        <td style="font-size:13px; font-weight:700; color:#2a2a2b;">{{ mostrarActualizarCosto.codigo }}</td>
                    </tr>
                    <tr>
                        <td style="font-size:13px; font-weight:700;">NOMBRE: </td>
                        <td colspan="3" style="font-size:13px; font-weight:700; color:#2a2a2b;">{{ mostrarActualizarCosto.nombre }}</td>
                    </tr>
                    <tr>
                        <td style="font-size:13px; font-weight:700;">MEDIDA: </td>
                        <td colspan="3" style="font-size:13px; font-weight:700; color:#1ABD07;">{{ mostrarActualizarCosto.medida }}</td>
                    </tr>
                    <tr>
                        <td style="font-size:13px; font-weight:700;">UNIDAD: </td>
                        <td colspan="3" style="font-size:13px; font-weight:700; color:#1ABD07;">{{ mostrarActualizarCosto.unidad }}</td>
                    </tr>
                    <tr>
                        <td style="font-size:13px; font-weight:700;">PROVEEDOR ACTUAL: </td>
                        <td colspan="3" style="font-size:13px; font-weight:700; color:#1ABD07;">{{mostrarActualizarCosto.proveedor }}</td>
                    </tr>
                    <tr>
                        <td style="font-size:13px; font-weight:700;">PRECIO COSTO COMPRA ACTUAL: </td>
                        <td colspan="3" style="font-size:13px; font-weight:700; color:#1ABD07;">{{mostrarActualizarCosto.costo }}</td>
                    </tr>
                </table>

                <div class="modal-body">
                    <label class="col-sm-12 control-label">NUEVO COSTO COMPRA: </label>
                    <input type="text" v-model.trim="actualizarCosto.costo" id="costo" placeholder="0.00" class="form-control form-control-lg" style="height: 50px; font-size: 27px;  border-width: 4px; border-color:#16870a; border-style: solid; color:#000000;">
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalPrecioCostoUnitario = false">
                    Cancelar
                </a-button>
                <a-button @click.once="realizarActualizacionCosto()" :key="buttonKey" type="inverse" :loading="loading">
                    Proceder Ahora
                </a-button>
            </template>
        </a-modal>

        <!-- GESTION KARDEX -->
        <a-modal
            v-model="modalkardex"
            title="Gestion de Kardex por Items"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1300"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <ModuloKardex 
                    :almacenid="kardex.almacenID" 
                    :productoid="kardex.productoID"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalkardex = false">
                    Cerrar Modal
                </a-button>
            </template>
        </a-modal>

        <!-- ELIMINAR INVENTARIO -->
        <a-modal
            v-model="openModalEliminarInventario"
            title="Nuevo Formato Inventario Sucursales"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Ingrese su Contraseña: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input-password size="large" v-model="inventario.password" placeholder="Ingresar Contraseña"  onfocus="myFunction(this)"/>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="openModalEliminarInventario = false">
                    Cancelar
                </a-button>
                <a-button @click.once="eliminarInventarioExistente()" :key="buttonKey" type="inverse" :loading="loading">
                    Proceder Ahora
                </a-button>
            </template>
        </a-modal>

        <!-- IMPORTAR INVENTARIO EXCEL MULTIPLE -->
        <a-modal
            v-model="showModalImportarExcelMultiple"
            title="Importar Inventario Excel Multiple"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="450"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="almacen_2" class="control-label">SUCURSALES</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.almacen_id }" @change="onSearchSucursal"  style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <div class="alert alert-warning" role="alert">
                            <strong>Atención!</strong> Los formatos que se aceptan son : .xlsx
                        </div>
                        <VueFileAgent
                            ref="vueFileAgent"
                            :multiple="false"
                            :deletable="true"
                            :meta="true"
                            :accept="'.xlsx, .xls,'"
                            :maxSize="'10MB'"
                            :maxFiles="1"
                            :helpText="'Agregue el Archivo Excel'"
                            :errorText="{
                                type: 'Tipo de archivo invalido. Solo se permiten .xlsx o xls.',
                                size: 'Los archivos no deben exceder los 10 MB de tamaño.',
                            }"
                            @select="filesSelected($event)"
                            @beforedelete="onBeforeDelete($event)"
                            @delete="fileDeleted($event)"
                        />
                    </div>						
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalImportarExcelMultiple = false">
                    Cancelar
                </a-button>
                <a-button @click.once="guardarImportacionExcelMultiple()" :key="buttonKeyImportacionExcelMultiple" type="inverse" :loading="loading">
                    Proceder Ahora
                </a-button>
            </template>
        </a-modal>

        <!-- GENERAR PRECIO COSTO -->
        <a-modal
            v-model="showModalGenerarCosto"
            title="GENERAR PRECIOS DE COMPRA"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="380"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <div class="col-md-12">
                    <div class="form-group">
                        <center><h2 style="color:#139e6a; font-weight:700;">{{ nombreAlmacenActual }}</h2></center>
                    
                        <a-alert message="Esta opción crea los precio de compra usando el precio de venta a partir de un porcentaje de ganancia asignado por el usuario, sobreescribirá todo el kardex e inventario" type="warning" show-icon />

                        <br>

                        <label>Ganancia en (%): <span style="font-size:14px; font-weight:700; color:#05729b;"> *</span></label>
                        <a-input-number 
                            size="large" 
                            :min="1" 
                            :max="100" 
                            :default-value="utilidad_compra" 
                            @change="onChangeMargenGanancia" 
                            onfocus="myFunction(this)" 
                            style="width: 100%"
                        />
                    </div>						
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalGenerarCosto = false">
                    Cancelar
                </a-button>
                <a-button @click.once="generarPrecioCompra()" :key="buttonKeyGenerarCompra" type="inverse" :loading="loading">
                    Proceder Ahora
                </a-button>
            </template>
        </a-modal>

        <!-- REALIZAR COMPRA -->
        <a-modal
            v-model="modalInventarioProducto"
            title="GESTION DE COMPRAS E INVENTARIO INICIAL"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1280"
            :zIndex="1049"
            >

            <div class="row mb-12">
                <ComponenteCompra
                    :utilidadporcentaje="this.utilidadporcentaje"
                    :preciocifradecimal="this.preciocifradecimal"
                    :preciocifraentera="this.preciocifraentera"
                    :precioreglatres="this.precioreglatres"
                    :monedasimbolo="this.monedasimbolo"
                    :dataproducto="this.compra.dataproducto"
                    :almacenid="this.compra.almacenID"
                    :proveedorid="this.compra.proveedorID"
                    @successCompra="dataSuccessCompra"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalInventarioProducto = false">
                    CERRAR
                </a-button>
            </template>
        </a-modal>

        <!-- EDITAR PRECIOS DE VENTA -->
        <a-modal
            v-model="openModalEditarPrecioVentaCompra"
            title="ASIGNAR PRECIOS DE VENTA AL PRODUCTO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="920"
            :zIndex="1040"
            >

            <div class="mb-12">
                <ModuloPrecioVenta 
                    :monedasimbolo="monedasimbolo"
                    :utilidadporcentaje="utilidadporcentaje"
                    :preciocifradecimal="preciocifradecimal"
                    :preciocifraentera="preciocifraentera"
                    :precioreglatres="precioreglatres"
                    :almacenid="this.asignacion.almacenID"
                    :productoid="this.asignacion.productoID"
                    :proveedorid="this.asignacion.proveedorID"
                    :utilidad="this.asignacion.utilidad"
                    :producto="this.asignacion.producto"
                    :preciocompra="this.asignacion.precioCosto"
                    @successVenta="dataSuccessPrecioVenta"
                />
            </div>
                        
            <template slot="footer">
                <a-button key="back" @click="openModalEditarPrecioVentaCompra = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>

        <!-- AJUSTE DE PRECIO DE VENTA -->
        <a-modal
            v-model="modalAjustePrecio"
            title="Corrección de Precios de Ventas"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="450"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <center><span style="font-weight:700; font-size:16px; color:green;">{{ search.nombreAlmacen }}</span></center>
                <div class="col-md-12">
                    <div class="form-group">
                        <p style="color:black; font-size:18px;">Este proceso corregira todos los precios de venta al proveedor actual que cada producto tiene registrado... "</p>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalAjustePrecio = false">
                    Cancelar
                </a-button>
                <a-button @click.once="AjustePrecioVenta()" :key="buttonKey" type="primary" :loading="loading">
                    Proceder Ahora
                </a-button>
            </template>
        </a-modal>

        <!-- GENERAR PRECIO DE VENTA -->
        <a-modal
            v-model="showModalGenerarVenta"
            title="Generar Precio de Ventas"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="350"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <div class="col-md-12">
                    <center><span style="font-weight:700; font-size:16px; color:green;">{{ search.nombreAlmacen }}</span></center>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="example ex1">
                                <label class="radio green">
                                    <input type="radio" v-model="radioporcentaje" :value="0" checked/>
                                    <span>Aplicar el impuesto del {{ porcentaje.iva }} %</span>
                                </label>
                                <br>
                                <label class="radio lila">
                                    <input type="radio" v-model="radioporcentaje" :value="1"/>
                                    <span>Aplicar el impuesto del {{ parseInt(porcentaje.iva) + parseInt(porcentaje.it) }} %</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <label for="almacen" class="control-label">ELEGIR TIPO PRECIO VENTA</label>
                            <a-select size="large" :value="asignacion.tipoprecioventaID" @change="onSelectTipoPrecioVenta" style="width: 100%">
                                <a-select-option v-for="(value, index) in lista_tipoprecioventa" :key="index" :value="value.tipoprecioventa_id"> {{ value.nombre }}</a-select-option>
                            </a-select>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-md-12">
                            <label>PORCENTAJE UTILIDAD %: </label>
                            <a-input type="text" class="form-control form-control-lg" v-model="asignacion.utilidad" data-parsley-trigger="keyup" data-parsley-validation-threshold="1" maxlength="100" data-parsley-pattern="^[ 0-9.]+$" data-parsley-minlength="1" data-parsley-maxlength="100" onKeyUp="stringMayus(this);" placeholder="Porcentaje Utilidad %" onfocus="myFunction(this)" />
                        </div>
                    </div>

                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalGenerarVenta = false">
                    Cancelar
                </a-button>
                <a-button @click.once="generarPrecioVenta()" :key="buttonKeyGenerarVenta" type="primary" :loading="loading">
                    Proceder Ahora
                </a-button>
            </template>
        </a-modal>

        <!-- ELIMINAR PRECIOS DE VENTA -->
        <a-modal
            v-model="showModalEliminarGenerarVenta"
            title="Eliminar todos los Precios de Venta segun Sucursal"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="420"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <center><span style="font-weight:700; font-size:16px; color:green;">{{ search.nombreAlmacen }}</span></center>
                <div class="col-md-12">
                    <a-alert message="Esta opción Eliminará todos los Precios de Venta que tenga Registrado y no podrá recuperar en el sistema segun la Sucursal elegida. Atencion!!! Se recomienda utilizar esta opción para cargar nuevamente los precios de venta segun el porcentaje" type="warning" show-icon />
                    <br>
                    <div class="form-group">
                        <label>Ingrese su Contraseña: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input-password size="large" v-model="precioventa.password" placeholder="Ingresar Contraseña"  onfocus="myFunction(this)"/>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalEliminarGenerarVenta = false">
                    Cancelar
                </a-button>
                <a-button @click.once="eliminarPrecioVentaMasivo()" :key="buttonKeyEliminarVenta" type="inverse" :loading="loading">
                    Proceder Ahora
                </a-button>
            </template>
        </a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>

    </div>
</template>

<script>
    const columns = [
        { 
            id: 'productoId', 
            title: 'Codigo', 
            dataIndex: '',
            key: 'codigo',
            scopedSlots: { customRender: 'codigo' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '20%'
        },
        { 
            id: 'productoId', 
            title: 'Proveedor', 
            dataIndex: '',
            key: 'proveedor',
            scopedSlots: { customRender: 'proveedor' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '17%'
        },
        { 
            id: 'productoId',
            title: 'Nombre', 
            dataIndex: '',
            key: 'nombre',
            width: '25%',
            scopedSlots: { customRender: 'nombre' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'productoId',
            title: 'Medida', 
            dataIndex: '',
            width: '15%',
            scopedSlots: { customRender: 'medida' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'productoId',
            title: 'Stock',
            dataIndex: '',
            key: 'saldoFisico',
            scopedSlots: { customRender: 'saldoFisico' }, 
            width: '12%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'productoId',
            title: 'P.Compra', 
            dataIndex: '',
            key: 'costo',
            width: '16%',
            scopedSlots: { customRender: 'costo' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'productoId',
            title: 'S.Valorado', 
            dataIndex: '',
            key: 'saldoValorado',
            width: '17%',
            scopedSlots: { customRender: 'saldoValorado' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'productoId',
            title: 'P.Venta', 
            dataIndex: '',
            key: 'precioventa',
            scopedSlots: { customRender: 'precioventa' }, 
            width: '35%'
        },
        { 
            id: 'productoId',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '15%'
        }
    ]

	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import { EuropioCode } from '@/utils/europiocode'
	import axios from '@/config/axios/axios'
	import Swal from 'sweetalert2'
    import { mapGetters, mapActions } from 'vuex'
    import File from '../../../components/File.vue'

    import ModuloKardex from './ModuloKardex.vue'
    import ComponenteCompra from '../../Compra/Components/ComponenteCompra.vue'
    import ModuloPrecioVenta from './ModuloPrecioVenta.vue'

    import { 
        URI_KARDEX_ACTUALIZAR_COSTO,
        URI_INVENTARIO_ACTUALIZAR_SALDO,
        URI_INVENTARIO_ACTUALIZAR_FECHA_KARDEX,
        URI_INVENTARIO_FORMATEAR,
        URI_INVENTARIO_ACTUALIZAR_COSTO,
        URI_TIPOPRECIOVENTA_LISTAR,
        URI_PORCENAJEIMPUESTO_LISTAR,
        URI_PRECIOVENTA_AJUSTE_GLOBAL,
        URI_PRECIOVENTA_GENERAR,
        URI_PRECIOVENTA_ELIMINAR_GLOBAL
	} from '@/utils/constants'

    export default {

        props: {
            utilidadporcentaje: { type: String, required: true },
            disabledvencimiento: { type: String, required: true },
            monedasimbolo: { type: String, required: true },
            preciocifradecimal: { type: String, required: true },
            preciocifraentera: { type: String, required: true },
            precioreglatres: { type: String, required: true },
            almacenid: { type: Number, required: true },
            userid: { type: Number, required: true },
            simbololiteralplural: { type: String, required: true }
        },

        components: {
            File,
            ModuloKardex,
            ComponenteCompra,
            ModuloPrecioVenta
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
				utils,
                columns,
                consulta: {
                    almacen_id: 'Seleccionar',
					usuario_id: 'Seleccionar',
                    proveedor_id: 'Seleccionar',
                    nombreAlmacen: '',
                    fecha_inicio: '',
                    fecha_final: '',
                    keyword: ''
                },
				lista_permisos: [],
                list: [],
                url: '',
                pagination: [],
                search: { keyword: '' },
                lista_almacenes: [],
                proveedor_id: '',
                lista_proveedores: [],
                clicked: false,
                actualizarCosto: {
                    costo: '0.00' 
                },
                mostrarActualizarCosto: {
                    almacen_id: 0,
                    producto_id: 0,
                    codigo: '',
                    descripcion: '',
                    medida: '',
                    nombre: '',
                    costo: '',
                    proveedor: '',
                    unidad: ''
				},
				showModalImportarExcel: false,
                showModalImportarExcelMultiple: false,
                buttonKey: 1,
                buttonKeyVisualizarKardex: 20,
                buttonKeyEditarCosto: 30,
                buttonKeyCompra: 40,
                buttonKeyNuevaVenta: 50,
                buttonKeyEliminarVenta: 90,
                buttonKeyGenerarVenta: 100,
				buttonKeyImportacionExcel: 150,
                buttonKeyPlantillaImportacionExcel: 180,
                buttonKeyImportacionExcelMultiple: 200,
                buttonKeyActualizarCostos: 300,
                buttonKeyActualizarSaldos: 400,
                buttonKeyActualizarFechas: 500,
                buttonKeyAjustePrecio: 600,
                buttonKeyPrecioVenta: 700,
                buttonKeyEliminarPrecioVenta: 800,
                buttonKeyEliminarInventario: 900,
				buttonKeyGenerarCompra: 1000,
                file: {
                    importacionExcel: []
                },
                importacionExcelFileMultiple: [],
				spinnerloading: false,
				showModalGenerarCosto: false,
				showModalActualizarFecha: false,
				nombreAlmacenActual: '',
				utilidad_compra: '',
				list_movimiento: [],
				lista_actualizar_fechas: [
					{ detalle: 2, nombre: 'TODAS LAS COMPRAS AL CONTADO' },
					{ detalle: 4, nombre: 'TODAS LAS VENTAS AL CONTADO' }
				],
				fechaestadoelegido: 2,
				openModalNuevaGestionModal: false,
				loading: false,
                modalkardex: false,
                kardex: {
                    almacenID: '',
                    productoID: ''
                },
                openModalEliminarInventario: false,
                inventario: {
                    password: ''
                },
                modalInventarioProducto: false,
                compra: {
                    compra_id: '',
                    almacenID: '',
                    proveedorID: '',
                    dataproducto: {}
                },
                modalPrecioCostoUnitario: false,
                asignacion: {
                    tipoprecioventaID: '',
                    almacenID: '',
                    productoID: '',
                    proveedorID: '',
                    utilidad: '',
                    precioventa: '',
                    producto: {},
                    precioCosto: '',
                    preciofacturado: ''
                },
                openModalEditarPrecioVentaCompra: false,
                modalAjustePrecio: false,
                showModalGenerarVenta: false,
                showModalEliminarGenerarVenta: false,
                lista_tipoprecioventa: [],
                porcentaje: {
                    iva: '',
                    it: ''
                },
                radioporcentaje: 0,
                precioventa: {
                    password: ''
                },
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'productoId',
                    order: 'descend'
                }
            }
        }, 

        created () {
            this.getAlmacenes()
            this.getProveedores()
            this.getInformacionSaldo()
            this.paginarResultados()
            this.getTipoPrecioVenta()
        },

        computed: {
            ...mapGetters('configuracion', ['config']),
            ...mapGetters('auth', ['user']),
            ...mapGetters('almacen', ['almacenes']),
            ...mapGetters('proveedor', ['proveedores']),
            ...mapGetters('inventario', ['inventarios', 'informacionSaldo', 'plantillaImportacion'])
        },

        methods: {
            ...mapActions('inventario', ['getInformacion', 'getListaInventario', 'importacionExcel', 'plantillaImportacionExcel']),
            ...mapActions('almacen', ['getAlmacenListado']),
            ...mapActions('proveedor', ['getProveedorListado']),

            filterOptionProveedor (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            getAlmacenes () {
                this.getAlmacenListado()
				.then(response => {
					this.consulta.almacen_id = this.user.almacenID.toString()
                    this.consulta.nombreAlmacen = Object.values(this.almacenes).filter(item => item.almacen_id === this.consulta.almacen_id).shift().nombre
				})
				.catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
				})
            },

            getProveedores () {
                this.getProveedorListado()
				.then(response => {
                    this.consulta.proveedor_id = Object.values(this.proveedores).filter(x => typeof x !== 'undefined').shift().proveedor_id
				})
				.catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
				})
            },

            resetConsultaPaginacion () {
                this.paginate = {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'productoId',
                    order: 'descend'
                }

				this.consulta.usuario_id = 'Seleccionar'
                this.consulta.proveedor_id = 'Seleccionar'
                this.consulta.fecha_inicio = ''
                this.consulta.fecha_final = ''
                this.consulta.keyword = ''
            },

            onSearchSucursal (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.almacen_id = value.key
                this.consulta.nombreAlmacen = value.label
                this.getInformacionSaldo()
                this.paginarResultados()
            },

            onSearchFechaElegido (value) {
                this.fechaestadoelegido = value
            },

            onSearchProveedor (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.proveedor_id = value
                this.getInformacionSaldo()
                this.paginarResultados()
            },

            onSearchProducto (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.keyword = value
                this.paginarResultados()
            },

            obtenerListadoActual (value) {
                this.resetConsultaPaginacion()
                this.paginarResultados()
            },

            getInformacionSaldo () {
				const consultaAlmacen = (this.consulta.almacen_id === 'Seleccionar') ? this.user.almacenID : this.consulta.almacen_id
				this.consulta.almacen_id = (this.$can('existencia.consultar.inventario.por.sucursal')) ? consultaAlmacen : this.user.almacenID
                this.getInformacion(this.consulta)
            },

            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'productoId',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'productoId',
                    order: 'descend'
                }
            },

            paginarResultados () {
				const consultaAlmacen = (this.consulta.almacen_id === 'Seleccionar') ? this.user.almacenID : this.consulta.almacen_id
				this.consulta.almacen_id = (this.$can('existencia.consultar.inventario.por.sucursal')) ? consultaAlmacen : this.user.almacenID

                this.getListaInventario({ ...this.paginate, ...this.consulta, ...this.user })
                .then(response => {
                    this.changePaginate(response.data.meta)
                    this.spinnerloading = false
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            /** MODULO KARDEX */
            openModalKardex (item) {
                this.kardex.almacenID = item.almacenId
                this.kardex.productoID = item.producto.producto_id
                this.modalkardex = true
                this.buttonKeyVisualizarKardex++
            },
             /** MODULO KARDEX */

            /** MODULO ACTUALIZACION DE PRECIO COSTO */
            abrirModalActualizarCosto (item) {
                this.mostrarActualizarCosto.almacen_id = item.almacenId
                this.mostrarActualizarCosto.producto_id = item.producto.producto_id
                this.mostrarActualizarCosto.codigo = item.producto.codigo
                this.mostrarActualizarCosto.descripcion = item.producto.descripcion
                this.mostrarActualizarCosto.medida = item.producto.medida
                this.mostrarActualizarCosto.nombre = item.producto.nombre
                this.mostrarActualizarCosto.costo = item.costo
                this.mostrarActualizarCosto.proveedor = item.producto.proveedor.empresa
                this.mostrarActualizarCosto.unidad = item.producto.unidad.nombre

                this.modalPrecioCostoUnitario = true
                this.buttonKeyEditarCosto++
            },

            realizarActualizacionCosto () {
                const costo = parseFloat(this.actualizarCosto.costo)
                if (costo <= 0.00) {
                    Swal.fire('No Realizado', 'El Costo de Compra es menor o igual a cero por favor ingrese un monto mayor...', 'error')
                    this.buttonKey++
                } else {
					this.buttonKey++

                    Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                    }).then((result) => {
                        if (result.value) {
                            const ecode = new EuropioCode()
                            const formData = new FormData()
                            formData.append('productoID', this.mostrarActualizarCosto.producto_id)
                            formData.append('almacenID', this.mostrarActualizarCosto.almacen_id)
                            formData.append('costoActual', ecode.encode(this.actualizarCosto.costo))

                            axios.post(URI_KARDEX_ACTUALIZAR_COSTO, formData)
                            .then(response => {
                                if (response.status === 201) {
                                    this.getAlmacenes()
                                    this.getInformacionSaldo()
                                    this.getProveedores()
                                    this.paginarResultados()
                                    this.modalPrecioCostoUnitario = false
                                }
                            })
                            .catch(error => {
                                utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                            })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                        }
                    })
                }
                this.buttonKey++
            },
            /** MODULO ACTUALIZACION DE PRECIO COSTO */

            /** MODULO PRECIO DE VENTAS */
            abrirModalAsignarPrecios (item) {
				if (parseInt(this.consulta.almacen_id) !== parseInt(item.almacenId)) {
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'Este producto debe ingresar como inventario inicial, verifique que este registrado en la sucursal... :).', 'topRight')
				} else {
                    this.asignacion = []
                    setTimeout(function () { 
                        item.producto.nombreAlmacen = item.nombreAlmacen

                        this.asignacion.producto = item.producto
                        this.asignacion.almacenID = parseInt(item.almacenId)
                        this.asignacion.productoID = item.producto.producto_id
                        this.asignacion.proveedorID = item.producto.proveedorID
                        this.asignacion.utilidad = item.producto.porcentajeUtilidad
                        this.asignacion.precioCosto = item.costo
                        this.openModalEditarPrecioVentaCompra = true
                    }.bind(this), 500)
                    this.buttonKeyNuevaVenta++
                }
			},

            dataSuccessPrecioVenta (newValue) {
                setTimeout(function () { 
                    this.paginarResultados()
                    this.buttonKeyNuevaVenta++
                }.bind(this), 300)
            },
            /** MODULO PRECIO DE VENTAS */

            /** MODULO DE COMPRAS */
            abrirModalInventarioProducto (item) {
                this.compra.almacenID = ''
                this.compra.proveedorID = ''
                this.compra.dataproducto = []
                this.compra.data = []

                setTimeout(function () {
                    const producto = item.producto
                    const almacen = item

                    this.compra.almacenID = item.almacenId.toString()
                    this.compra.proveedorID = item.producto.proveedorID.toString()
                    this.compra.dataproducto = { ...producto, ...almacen }
                    this.modalInventarioProducto = true
                    this.buttonKeyCompra++
                }.bind(this), 500)
            },

            dataSuccessCompra (newValue) {
                setTimeout(function () { 
                    this.paginarResultados()
                    this.modalInventarioProducto = false
                    this.buttonKeyCompra++
                }.bind(this), 300)
            },
            /** MODULO DE COMPRAS */

            /** GENERAR PRECIOS DE COMPRA */
            AbrirActualizarCostos () {
				this.showModalGenerarCosto = true
			},

            onChangeMargenGanancia (value) {
                this.utilidad_compra = value
            },

            generarPrecioCompra () {
				Swal.fire({
                    title: 'Desea Generar Precios de Compra ?',
                    text: 'Esta opción generará los precios de compra a partir del precio de venta, Atencion!!! Se recomienda utilizar esta opción por primera vez.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
						const formData = new FormData()
						formData.append('almacenID', (this.consulta.almacen_id === null) ? '' : this.consulta.almacen_id)
						formData.append('utilidad', (this.utilidad_compra === null) ? '' : this.utilidad_compra)
					    
                        axios.post(URI_INVENTARIO_ACTUALIZAR_COSTO, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.getAlmacenes()
							    this.paginarResultados()
							    this.getInformacionSaldo()
							    this.getProveedores()
							    this.showModalGenerarCosto = false
							    this.spinnerloading = false
                                utils.openNotificationWithIcon('info', 'Mensaje : ', 'La actualizacion de los Costos se ha registrado Correctamente.', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKeyGenerarCompra++
			},
            /** GENERAR PRECIOS DE COMPRA */

            /** ACTUALIZAR SALDOS */
            ActualizarSaldos () {
				Swal.fire({
					html: '<h3 style="color:#139e6a; font-weight:700;">' + this.consulta.nombreAlmacen + ' </h3>',
                    title: 'Desea Actualizar los Saldos del Sistema ?',
                    text: 'Esta opción Actualizara los saldos del Sistema corrigiendo errores del inventario.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
						const formData = new FormData()
						formData.append('almacenID', (this.consulta.almacen_id == null) ? '' : this.consulta.almacen_id)

                        axios.post(URI_INVENTARIO_ACTUALIZAR_SALDO, formData)
                        .then(response => {
                            if (response.status === 201) {
							    this.getAlmacenes()
							    this.paginarResultados()
							    this.getInformacionSaldo()
							    this.getProveedores()
							    this.spinnerloading = false
							    this.showModalImportarExcel = false
                                utils.openNotificationWithIcon('info', 'Mensaje : ', 'La actualización de Saldos fue realizado con éxito.', 'topRight')
                            }
						})
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
					} else if (result.dismiss === Swal.DismissReason.cancel) {
						Swal.fire(
							'No Realizado',
							'No has realizado ninguno :(',
							'error'
						)
					}
				})
			},
            /** ACTUALIZAR SALDOS */

            /** CORREGIR FECHAS DE INVENTARIO */
            AbrirActualizarFechas () {
				this.showModalActualizarFecha = true
			},

			ActualizarFechas () {
				Swal.fire({
					html: '<h3 style="color:#139e6a; font-weight:700;">' + this.consulta.nombreAlmacen + ' </h3>',
                    title: 'Desea Actualizar las fechas de kardex del Sistema ?',
                    text: 'Esta opción Actualizará las fecha del kardex del Sistema corrigiendo errores del inventario.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
						const formData = new FormData()
						formData.append('almacenID', (this.consulta.almacen_id == null) ? '' : this.consulta.almacen_id)
						formData.append('detalle', (this.fechaestadoelegido == null) ? '' : this.fechaestadoelegido)

                        axios.post(URI_INVENTARIO_ACTUALIZAR_FECHA_KARDEX, formData)
                        .then(response => {
							this.getAlmacenes()
							this.paginarResultados()
							this.getInformacionSaldo()
							this.getProveedores()
							this.spinnerloading = false
                            this.showModalActualizarFecha = false
                            utils.openNotificationWithIcon('info', 'Mensaje : ', 'La actualización de Saldos fue realizado con éxito.', 'topRight')
						})
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
					} else if (result.dismiss === Swal.DismissReason.cancel) {
						Swal.fire(
							'No Realizado',
							'No has realizado ninguno :(',
							'error'
						)
					}
				})
				this.buttonKeyGenerarCompra++
			},
            /** CORREGIR FECHAS DE INVENTARIO */

            /** AJUSTE PRECIO DE VENTA */
            abrirAjustePrecio () {
				this.modalAjustePrecio = true
			},

            AjustePrecioVenta () {
				Swal.fire({
                        title: 'Desea Corregir los Precios de Venta?',
                        text: 'Tome en cuenta que se corregiran todos los precios de venta de acuerdo al proveedor establecido para cada producto.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
						const formData = new FormData()
						formData.append('almacenID', (this.search.almacenID) ? this.search.almacenID : this.almacenid)

                        axios.post(URI_PRECIOVENTA_AJUSTE_GLOBAL, formData)
						.then(response => {
                            if (response.status === 201) {
                                this.paginarResultados()
							    this.spinnerloading = false
							    this.modalAjustePrecio = false
                                utils.openNotificationWithIcon('info', 'Mensaje : ', 'Se ha corregido con exito todos los precios de venta', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKey++
			},
            /** AJUSTE PRECIO DE VENTA */

            /** GENERAR PRECIO DE VENTA */
            getTipoPrecioVenta () {
                axios.get(URI_TIPOPRECIOVENTA_LISTAR)
                .then(response => {
                    this.lista_tipoprecioventa = response.data
                    this.asignacion.tipoprecioventaID = Object.values(this.lista_tipoprecioventa).filter(x => typeof x !== 'undefined').shift().tipoprecioventa_id.toString()
                })
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            onSelectTipoPrecioVenta (value) {
                this.asignacion.tipoprecioventaID = value
            },

            abrirPrecioVenta () {
				this.showModalGenerarVenta = true
				this.obtenerPorcentajeImpuestoPrecioVenta()
			},

            obtenerPorcentajeImpuestoPrecioVenta () {
                const formData = new FormData()
                formData.append('almacenID', (this.search.almacenID) ? this.search.almacenID : this.almacenid)

                axios.post(URI_PORCENAJEIMPUESTO_LISTAR, formData)
                .then(response => {
                    this.porcentaje.iva = response.data.iva
                    this.porcentaje.it = response.data.it
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
			},

            generarPrecioVenta () {
				Swal.fire({
                    title: 'Desea Generar Precios de Venta ?',
                    text: 'Esta opción generará los precios de venta a partir del precio de compra, el sistema eliminirá todos los precios de venta actualmente registrados y los calculará en base al porcentaje de utilidad que se ingresará, Atencion!!! Se recomienda utilizar esta opción por primera vez.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
						const formData = new FormData()
						formData.append('almacenID', (this.search.almacenID) ? this.search.almacenID : this.almacenid)
						formData.append('utilidad', (this.asignacion.utilidad === null) ? '' : this.asignacion.utilidad)
						formData.append('tipoprecioventaID', (this.asignacion.tipoprecioventaID === 0) ? '' : this.asignacion.tipoprecioventaID)
						const porcentaje = (this.radioporcentaje === 0) ? this.porcentaje.iva : parseInt(this.porcentaje.iva) + parseInt(this.porcentaje.it)
						formData.append('porcentaje', (porcentaje === null) ? '' : porcentaje)

                        axios.post(URI_PRECIOVENTA_GENERAR, formData)
                        .then(response => {
                            if (response.status === 201) {
								this.getAlmacenes()
                                this.getProveedores()
                                this.paginarResultados()
								this.showModalGenerarVenta = false
								this.spinnerloading = false
							} 
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKeyGenerarVenta++
			}, 
            /** GENERAR PRECIO DE VENTA */

            /** ELIMINAR PRECIO DE VENTA MASIVO */
            abrirEliminarPrecioVenta () {
				this.showModalEliminarGenerarVenta = true
				this.obtenerPorcentajeImpuestoPrecioVenta()
			},

			eliminarPrecioVentaMasivo () {
				Swal.fire({
                    title: 'Desea Eliminar los Precios de Venta?',
                    text: 'Esta opción Eliminará todos los Precios de Venta que tenga Registrado y no podrá recuperar en el sistema segun la Sucursal elegida. Atencion!!! Se recomienda utilizar esta opción para cargar nuevamente los precios de venta segun el porcentaje.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
                        const ecode = new EuropioCode()
                        const formData = new FormData()
                        formData.append('almacenID', (this.search.almacenID) ? this.search.almacenID : this.almacenid)
                        formData.append('password', this.precioventa.password)

                        axios.post(URI_PRECIOVENTA_ELIMINAR_GLOBAL, formData)
                        .then(response => {
                            if (response.status === 201) {
								this.getAlmacen()
                                this.getProveedor()
                                this.paginarResultados()
								this.showModalEliminarGenerarVenta = false
                                this.precioventa.password = ''
								this.spinnerloading = false
							}
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
			},
            /** ELIMINAR PRECIO DE VENTA MASIVO */

            abrirModalEliminarInventario () {
				this.openModalEliminarInventario = true
			}, 
			
			eliminarInventarioExistente () {
                Swal.fire({
					    html: '<h3 style="color:#139e6a; font-weight:700;"> TODOS LOS SUCURSALES </h3>',
                        title: 'Desea Formatear el Inventario de todos los sucursales?',
                        text: 'Tome en cuenta que al formatear el inventario se eliminará todos los productos del sistema de todas las sucursales.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
                        this.buttonKey++
                        const ecode = new EuropioCode()
                        const formData = new FormData()
                        formData.append('almacen_id', (this.consulta.almacen_id == null) ? '' : this.consulta.almacen_id)
                        formData.append('password', ecode.encode(this.inventario.password))

                        axios.post(URI_INVENTARIO_FORMATEAR, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.getAlmacenes()
                                this.paginarResultados()
                                this.getInformacionSaldo()
                                this.getProveedores()
                                this.spinnerloading = false
                                this.openModalEliminarInventario = false
                                this.inventario.password = ''
                                Swal.fire('Éxito!', 'Has Realizado Correctamente.', 'success')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKey++
            },

            descargaPlantillaExcel () {
                Swal.fire({
                        title: 'Desea Descargar la Plantilla para la Importacion?',
                        text: 'Tome en cuenta que esta plantilla sirve exclusivamente para la importacion de inventario al Sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
						this.plantillaImportacionExcel()
                        .then(response => {
                            if (response.status === 200) {
                                window.location.href = this.plantillaImportacion.link
                                this.spinnerloading = false
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKeyPlantillaImportacionExcel++
            },

			abrirImportarExcel () {
				this.showModalImportarExcel = true
			},

            successFileImportacion (value) {
                this.file.importacionExcel = value
            },

			guardarImportacionExcel () {
				Swal.fire({
                    title: 'Desea Importar Datos al Sistema ?',
                    text: 'Esta opción guardara todos los datos existentes en el sistema y los reemplazara por la nueva Importación, Atención!!! Esta opción se recomienda utilizar la primera vez del uso del sistema.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
						this.importacionExcel({ ...this.file, ...this.consulta, ...this.user })
                        .then(response => {
                            if (response.status === 201) {
                                this.getInformacionSaldo()
                                this.paginarResultados()
                                this.showModalImportarExcel = false
                                this.spinnerloading = false
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKeyImportacionExcel++
			}
        }
    }
</script>

<style>

</style>