<template>
    <div>
        <ModuloInventario 
            :monedasimbolo="config.monedasimbolo"
            :userid="config.userid"
            :almacenid="config.almacenid"
            :preciocifradecimal="config.preciocifradecimal" 
            :preciocifraentera="config.preciocifraentera" 
            :precioreglatres="config.precioreglatres" 
            :utilidadporcentaje="config.utilidadporcentaje"
            :compradecimales="config.compradecimales"
            :simbololiteralplural="config.monedaliteralplural"
            :disabledvencimiento="config.disabledvencimiento"
        />
    </div>
</template>
    
<script>
    import ModuloInventario from './Modulos/ModuloInventario.vue'
    
    export default {
        
        props: [
            'usuario',
            'config'
        ],

        components: {
            ModuloInventario
        }

    }
</script>